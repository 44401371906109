import "bootstrap/dist/css/bootstrap.min.css";
import "./AccordionSection.css";
import React from "react";
import AccordionElement from "./AccordionElement";
import { Accordion, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export class AccordionSection extends React.Component {
  constructor(props) {
    super(props);
    this.sectionTitle = this.props.sectionName;
    this.count = 0;
    this.state = { copySuccess: "" };
  }

  increaseCount() {
    return (this.count += 1);
  }

  render() {
    return (
      <div className="AccordionSection">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Quicksand:regular,medium,semi-bold,bold"
        ></link>
        <div className="AccordionSection-content">
          <h2 className="AccordionSection-title">{this.sectionTitle}</h2>
          <Accordion>
            <AccordionElement
              question="Is There A Minimum Fee To Use Your Bookkeeping Services?"
              answer="No.  We do not charge a minimum fee – we charge a flat hourly rate for all services and 
              understand all companies, regardless of size, need high-quality bookkeeping support."
              eventKey={this.count}
            />
            <AccordionElement
              question="Are You Currently Taking On New Clients?"
              answer="We are taking on new clients, but we’re selective about who we work with as we want to ensure 
                    it’s a mutually positive fit for the client and for Bluebird."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="What Kind Of Companies Do You Work With?"
              answer="Our clients include technical and professional firms such as software developers, 
                    human resources consultants, PR and Marketing firms, design firms, and medical providers who 
                    need business bookkeeping services.  Our clients are service providers who generate income by delivering
                    quality services across a wide range of industries.  We do not work with individuals on managing their 
                    personal financial matters and we also tend to avoid companies with inventory."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="When In The Year Should I Get Started With Bookkeeping Services?"
              answer="Anytime of year is a good time to get your books organized and establish a recurring maintenance schedule.  
                    If you’re starting a new business, it’s never too early to start interviewing bookkeepers.  You want to make sure you find someone you 
                    can not only trust but also can easily communicate with.  Establishing a strong and comfortable working relationship early on 
                    is essential to ensuring the financial side of the business is running smoothly.  If you’re able to identify a bookkeeper early, 
                    you can leverage their expertise and best practices to identify the most effective tools, payroll provider, and processes 
                    upfront without trial and error. \n

                    One thing to note – by the time year-end (November/December) rolls around, bookkeepers are in full swing supporting their current 
                    clientele on year-end tax planning and ensuring everything is ready for the upcoming tax deadlines.  Reaching out to bookkeepers in the 
                    last quarter of the year is not unheard of but you’re much better off trying to contact bookkeepers earlier in the year especially if you 
                    need help with the current year’s books."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="Do You Come To The Office Or Is It All Virtual?"
              answer="Bluebird Bookkeeping is 100% virtual.  We have found there are so many benefits to this situation – since we’re not commuting 
              from client to client, we’re more available to assist our clients with their needs during the business day and are able to 
              turn urgent requests around quickly.  In addition, by using our own set-up we’re able to work more efficiently since we have 
              everything at our fingertips and are able to utilize our ideal work set up."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="Do You Support Quickbooks Software? Quickbooks Online?"
              answer="Bluebird Bookkeeping is 100% QuickBooks Online.  We know there are many great packages out there including 
              desktop versions of QuickBooks as well as Xero and there are some great bookkeepers out there who support those 
              packages however, Bluebird isn’t one of them.  We’ve found QuickBooks Online to be the best program out there for 
              us and have worked with many clients to transition over and leverage the great features of this web-based accounting 
              system."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="Do You Work With Paychex And/Or ADP?"
              answer="While we have our own opinions about payroll providers and their pros and cons, we understand many of our 
              clients have long-standing relationships with their payroll providers and are able to support most if not all of 
              the common payroll providers including Gusto, Quickbooks Online Payroll, Intuit Online Payroll, ADP, Paychex, 
              and SurePayroll, to name a few."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="What Payroll Support Do You Offer?"
              answer="In addition to handling your normal payroll needs we also have experience in implementing and managing 
              commuter benefits, 401k plans (including the annual census process), and the annual workers compensation audit 
              process."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="Are You A CPA?"
              answer="While we are not CPAs, we require all of our clients to engage with a CPA, Enrolled Agent or tax professional. 
               We recognize finding a good tax resource can be a little difficult but not to worry!  We have a short list of 
               excellent CPAs and Enrolled Agents we love working with and would highly recommend depending on your specific needs 
               and situation."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="Do You File My Taxes?"
              answer="While we don’t file taxes, we handle everything related to the tax filing in partnership with your CPA/tax 
              preparer.  We have established many strong relationships with our clients’ tax professionals and have an open line 
              of communication throughout the year – not just at tax time – to ensure the books are in perfect condition for all 
              tax filing needs."
              eventKey={this.increaseCount()}
            />
            <AccordionElement
              question="What's The Difference Between You And Your Competitors?"
              answer="There are so many bookkeepers and accounting firms out there which means the choices are vast and perhaps 
              daunting.  Because we are a small and established firm we are selective because prior experience has taught us what 
              does and doesn’t work.  We provide solid, reliable bookkeeping services to service providers who may not have the time
               to dive into their financials but understand and appreciate the importance of accurate books.  We are thorough, strong 
               communicators and partner with our clients to make sure their needs are met.  There is a mutual respect where we 
               understand every client is NOT the same and our clients quickly find working with Bluebird is a unique and personal 
               relationship where they feel supported and confident their accounting records are in excellent hands."
              eventKey={this.increaseCount()}
            />
          </Accordion>
        </div>
      </div>
    );
  }
}

export default AccordionSection;
