// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-body {
    text-align: left;
}

.accordion-button {
    font-weight: bold;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: #e5eef8;
}`, "",{"version":3,"sources":["webpack://./src/AccordionElement.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".accordion-body {\n    text-align: left;\n}\n\n.accordion-button {\n    font-weight: bold;\n}\n\n.accordion-button:not(.collapsed) {\n    color: black;\n    background-color: #e5eef8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
