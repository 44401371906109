// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
}

.Section-content {
  width: 80%;
  text-align: left;
}

.Section-title {
  font-family: "Quicksand", serif;
  font-weight: 700;
  font-size: 40px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 30px;

  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
}

.Section-text {
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.lists {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media screen and (max-width: 500px) {
    font-size: 10pt;
  }
}

ul {
  margin-left: -20px;
}

li {
  padding: 0 0 5px 5px;
}

li::marker {
  content: "✦";
}

.secondary li::marker {
  content: "✧";
}

.secondary li {
  padding: 0 0 2px 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Section.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;;EAEpB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,gBAAgB;;EAEhB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;;EAEtB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".Section {\n  width: 100%;\n  color: black;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding-bottom: 20px;\n}\n\n.Section-content {\n  width: 80%;\n  text-align: left;\n}\n\n.Section-title {\n  font-family: \"Quicksand\", serif;\n  font-weight: 700;\n  font-size: 40px;\n  text-align: left;\n  padding-top: 20px;\n  padding-bottom: 30px;\n\n  @media screen and (max-width: 500px) {\n    font-size: 28px;\n  }\n}\n\n.Section-text {\n  text-align: left;\n\n  @media screen and (max-width: 500px) {\n    font-size: 12px;\n  }\n}\n\n.lists {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n\n  @media screen and (max-width: 500px) {\n    font-size: 10pt;\n  }\n}\n\nul {\n  margin-left: -20px;\n}\n\nli {\n  padding: 0 0 5px 5px;\n}\n\nli::marker {\n  content: \"✦\";\n}\n\n.secondary li::marker {\n  content: \"✧\";\n}\n\n.secondary li {\n  padding: 0 0 2px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
