import "./Section.css";

function Section(props) {
  return (
    <div className="Section">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Quicksand:regular,medium,semi-bold,bold"
      ></link>
      <div className="Section-content">
        <h2 className="Section-title">{props.sectionName}</h2>
        {props.sectionText ? (
          <p className="Section-text">{props.sectionText}</p>
        ) : (
          <p className="Section-text">
            We are accepting new clients on a <b>referral basis only</b>. If our
            contact information has been shared with you by a friend or
            colleague we look forward to hearing from you.
          </p>
        )}
      </div>
    </div>
  );
}

export default Section;
