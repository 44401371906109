import bluebird from "./bluebird.png";
import header_image from "./banner.jpg";
import Section from "./Section";
import AccordionSection from "./AccordionSection";
import ListedSection from "./ListedSection";
import about_us_text from "./about-us-text.js";
import contact_us_text from "./contact_us_text.js";
import "./App.css";
import React from "react";
// import { BrowserRouter, Link } from 'react-router-dom';
import { Link, Element } from "react-scroll";

function App() {
  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Quicksand:regular,medium,semi-bold,bold"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap"
        rel="stylesheet"
      ></link>
      <header className="App-nav">
        {/* <a className="App-logo-container">
          <img src={bluebird} className="bb-logo" alt="logo" />
        </a> */}
        <div class="header-title">bluebird bookkeeping</div>
        <div className="App-nav-links">
          <Link
            to="about-us"
            spy={true}
            smooth={true}
            duration={100}
            className="App-nav-link"
            activeClass="true"
          >
            {" "}
            About Us{" "}
          </Link>
          <div className="App-nav-link-decoration">✧</div>
          <Link
            to="services"
            spy={true}
            smooth={true}
            duration={100}
            className="App-nav-link"
            activeClass="true"
          >
            Services
          </Link>
          <div className="App-nav-link-decoration">✧</div>
          <Link
            to="faq"
            spy={true}
            smooth={true}
            duration={100}
            className="App-nav-link"
            activeClass="true"
          >
            FAQ
          </Link>
          <div className="App-nav-link-decoration">✧</div>
          <Link
            to="contact-us"
            spy={true}
            smooth={true}
            duration={100}
            className="App-nav-link"
            activeClass="true"
          >
            Contact Us
          </Link>
        </div>
      </header>
      <div className="header-image-container">
        <img src={header_image} className="header-image" alt="divider" />
      </div>
      <div className="section-container">
        <Element name="about-us">
          <Section sectionName="About Us" sectionText={about_us_text} />
        </Element>
        <hr className="App-divider" />
        <Element name="services">
          <ListedSection sectionName="Services" id={"section02"} />
        </Element>
        <hr className="App-divider" />
        <Element name="faq">
          <AccordionSection sectionName="FAQ" id={"section03"} />
        </Element>
        <hr className="App-divider" />
        <Element name="contact-us">
          <Section sectionName="Contact Us" />
        </Element>
      </div>
      <div className="App-footer"></div>
    </div>
  );
}

export default App;
