import './AccordionElement.css';
import React from 'react';
import { Accordion } from 'react-bootstrap';

export class AccordionElement extends React.Component {
    constructor(props) {
        super(props)
        this.question = this.props.question;
        this.answer = this.props.answer;
        this.eventKey = this.props.eventKey;
        
    }

    render() {
        return (
            <Accordion.Item eventKey={this.eventKey}>
            <Accordion.Header>{this.question}</Accordion.Header>
            <Accordion.Body>
            {this.answer}
            </Accordion.Body>
        </Accordion.Item>
          );

    }
}

export default AccordionElement;