import "./Section.css";

function ListedSection(props) {
  return (
    <div className="Section">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Quicksand:regular,medium,semi-bold,bold"
      ></link>
      <div className="Section-content">
        <h2 className="Section-title">Services</h2>
        <p className="Section-text">
          We provide a full range of bookkeeping support to service-based
          businesses - some of our services include: <br />
        </p>
        <p className="lists">
          <ul class="services-list">
            <li>Financial statement preparation and analysis</li>
            <li>Payroll</li>
            <li>Forecasting and budgeting</li>
            <li>Tax liaison with our clients’ tax preparers</li>
            <li>Accounts Receivable</li>
            <ul className="secondary">
              <li>Client invoicing</li>
              <li>Billable expense tracking</li>
              <li>Collections</li>
            </ul>
            <li>Accounts Payable</li>
            <ul className="secondary">
              <li>Expense tracking - cash/accrual</li>
              <li>Bill entry and payment </li>
              <li>1099 reporting</li>
            </ul>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default ListedSection;
